import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import BackNavBar from '@src/components/backNavBar';
import BodyText from '@components/bodyText';
import Contact from '../../components/contact';

import Layout from '@components/layout';

const AllmannaVillkorFakturaKredit = ({ data }) => {
  const headLine = data.contentfulDescriptionComponent.headLine;
  const bodyText = data.contentfulDescriptionComponent.bodyText;

  return (
    <Layout newStyle opaqueNavbar>
      <SEO title={headLine} />
      <BackNavBar text="Tillbaka till villkor" back="/nordiska"></BackNavBar>
      <div style={{ marginBottom: '60px' }} id="first">
        <BodyText bodyText={bodyText} />
      </div>
      <Contact />
    </Layout>
  );
};

export default AllmannaVillkorFakturaKredit;

AllmannaVillkorFakturaKredit.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query NordiskaAllmannaVillkorQuery {
    contentfulDescriptionComponent(
      id: { eq: "cdfde32c-5404-5355-8be4-bbf5fdc51a60" }
    ) {
      bodyText {
        childMarkdownRemark {
          html
        }
      }
      headline
    }
  }
`;
